@import "~@mdi/font/css/materialdesignicons.css";
@import "colors";

body {
  margin: 0;
  font-family: 'Lexend Deca', 'sans-serif';
  background-size: cover;
  // background-color: $dark-accent-color;
  background-color: black;
  font-size: 1rem;
  font-weight: lighter;
  letter-spacing: 0.75px;
}

@media (min-resolution: 2dppx) {
  body {
    font-size: 1.125rem; /* 18px equivalent */
  }
}

@media (min-resolution: 3dppx) {
  body {
    font-size: 1.25rem; /* 20px equivalent */
  }
}

span, p {
  font-weight: lighter;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.content-holder {
  margin: 0 auto;
  vertical-align: middle;
  text-align: left;
  color: $dark-font-color !important;

}

.content-holder h1 {
  font-weight: bold;
}


.content-holder-statement {
  font-weight: lighter;
  color: white;
}

.background-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;

}

@media (min-width: 992px) {
  .background-video {
    transform: scale(2) translateX(-55px);
  }
}

.overlay {
  position: fixed;
}

.button-main, .button-main-sm {
  color: white;
  font-family: 'Inter', sans-serif;
  border-radius: 8px;
  font-size: 0.9rem;
  max-width: 250px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  position: relative;
  font-weight: lighter;
  line-height: normal;
  padding: 16px 30px;
  margin-top: 16px;
  text-decoration: none;
  //background: linear-gradient(90deg, rgba(108, 64, 201, 1) 0%, rgba(86, 58, 217, 1) 44%, rgba(30, 110, 232, 1) 85%, rgba(48, 131, 224, 1) 100%);
  border: 1px solid $base-color;
}

.border-anim::after {
  border-color: white;
  animation: border-animation 2s linear infinite;
}

.border-anim::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  border: 1px solid rgba(255, 0, 89, 0.34);
  border-radius: 8px;
  box-sizing: border-box;
  pointer-events: none;
  transition: all 0.4s ease;
}

@keyframes border-animation {
  0% {
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
  }
  12.5% {
    top: 0;
    left: 25%;
    width: 50%;
    height: 1px;
  }
  25% {
    top: 0;
    left: calc(100% - 2px);
    width: 1px;
    height: 1px;
  }
  37.5% {
    top: 25%;
    left: calc(100% - 2px);
    width: 1px;
    height: 50%;
  }
  50% {
    top: calc(100% - 2px);
    left: calc(100% - 2px);
    width: 1px;
    height: 1px;
  }
  62.5% {
    top: 100%;
    left: 50%;
    width: 50%;
    height: 1px;
  }
  75% {
    top: 100%;
    left: 0;
    width: 1px;
    height: 1px;
  }
  82.5% {
    top: 50%;
    left: 0;
    width: 1px;
    height: 50%;
  }
  100% {
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
  }
}

.button-main-light {
  background-color: $base-color;

  &.disabled {
    //opacity: 0.33;
  }
}

.filled {
  background-color: $base-color;
}

.button-main-sm {
  padding: 12px 30px;
  margin: 0 0 0 30px;
  width: 100%;
}

.button-main:hover, .button-main-sm:hover {
  background-color: rgba(38, 80, 115, 0.17);
  backdrop-filter: blur(4px);
  color: white;
  cursor: pointer;
}

.with-bg {
  background-color: white;
  color: #282c34;
  width: 100%;
}


@keyframes shine {
  to {
    background-position: 200% center;
  }
}

.highlight {
  color: $base-color;
}

.hero {
  background-color: rgba(215, 232, 255, 0.07);
  padding: 40px;
  border-radius: 16px;
  backdrop-filter: blur(16px);
}

.pb-8 {
  padding-bottom: 8rem;
}

.btn-primary {
  background-color: $base-color;
  border-color: $base-color;
}


.btn-primary.disabled, .btn-primary:disabled {
  background-color: $base-color-disabled;
  border-color: $base-color-disabled;
}

a {
  text-decoration: none;
}

a:hover {
  color: $base-color;
}

input {
  border-radius: 4px;
  border: 1px solid $dark-font-color;
  font-weight: lighter;
}

p {
  padding: 20px 0;
}

@font-face {
  font-family: 'Lexend Deca';
  src: local('Lexend Deca'), url('./fonts/LexendDeca-ExtraLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend Deca';
  src: local('Lexend Deca'), url('./fonts/LexendDeca-SemiBold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend Deca';
  src: local('Lexend Deca'), url('./fonts/LexendDeca-ExtraLight.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}