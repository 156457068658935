$base-color: #ff0059;
$base-color-disabled: rgba(255, 0, 89, 0.66);
$blue-accent: #44a1ff; // #006fff
$green-accent: #00E676;
$dark-accent-color: #010325;
$font-main-color: white; //#282c34
$dark-font-color: #5b677e;
$dark-background-color: #111827;
$dark-border-color: rgba(17, 24, 39, 0.33);
:export {
  baseColor: $base-color;
  baseColorDisabled: $base-color-disabled;
  blueAccent: $blue-accent;
  greenAccent: $green-accent;
  darkBackgroundColor: $dark-background-color;
  darkBorderColor: $dark-border-color;
}