@use './util/capsize';
@use './responsive' as r;

@import '../colors';

// Quicksand
@mixin quicksand($cap-height, $line-gap) {
    font-family: 'Quicksand', sans-serif;
    @include capsize.capsize(
        (
            'capHeight': 700,
            'ascent': 1000,
            'descent': -250,
            'unitsPerEm': 1000,
        ),
        $cap-height,
        $line-gap
    );
}
// Inter
@mixin inter($cap-height, $line-gap) {
    font-family: 'Inter', sans-serif;
    @include capsize.capsize(
                    (
                            'capHeight': 2048,
                            'ascent': 2728,
                            'descent': -680,
                            'unitsPerEm': 2816,
                    ),
                    $cap-height,
                    $line-gap
    );
}

// Lexend Deca
@mixin lexend-deca($cap-height, $line-gap) {
    font-family: 'Lexend Deca', sans-serif;
    @include capsize.capsize(
                    (
                            'capHeight': 700,
                            'ascent': 1000,
                            'descent': -250,
                            'unitsPerEm': 1000,
                    ),
                    $cap-height,
                    $line-gap
    );
}

@mixin space-grotesk($cap-height, $line-gap) {
    font-family: 'Space Grotesk', sans-serif;
    @include capsize.capsize(
        (
            'capHeight': 700,
            'ascent': 984,
            'descent': -292,
            'unitsPerEm': 1000,
        ),
        $cap-height,
        $line-gap
    );
}

@mixin lexend-deca($cap-height, $line-gap) {
    font-family: 'Lexend Deca', sans-serif;
    @include capsize.capsize(
        (
            'capHeight': 700,
            'ascent': 1000,
            'descent': -250,
            'unitsPerEm': 1000,
        ),
        $cap-height,
        $line-gap
    );
}


// CARD

.my-card {
    @include lexend-deca(36, 16); // Headline Large
    font-weight: 700;
    -webkit-font-smoothing: subpixel-antialiased;
    @include r.lg {
        @include lexend-deca(64, 16);
    }
}

// HEADINGS
h1,
.type-h1,
.type-headline-lg {
  @include lexend-deca(36, 16); // Headline Large
  font-weight: 700;
  letter-spacing: -2px;
  -webkit-font-smoothing: subpixel-antialiased;
  @include r.lg {
    @include lexend-deca(64, 16);
  }
}

h2,
.type-h2,
.type-headline-md {
  @include lexend-deca(24, 16); // Headline Medium
  font-weight: 300 !important;
  letter-spacing: -1.5px !important;
  -webkit-font-smoothing: subpixel-antialiased;
}

h3,
.type-h3,
.type-headline-sm {
  @include lexend-deca(24, 12); // Headline Small
  font-weight: 400;
  -webkit-font-smoothing: subpixel-antialiased;
}

h4,
.type-h4,
.type-title-lg {
  @include lexend-deca(12, 12); // Title Large
  font-weight: 300;
  -webkit-font-smoothing: subpixel-antialiased;

  @include r.lg {
    @include lexend-deca(16, 12);
  }
}

h5,
.type-h5,
.type-title-md {
  @include lexend-deca(14, 12); // Title Medium
  font-weight: 300;
  -webkit-font-smoothing: subpixel-antialiased;
}

h6,
dt,
.type-h6,
.type-title-sm {
    @include quicksand(12, 12); // Title Small
    font-weight: 700;
    -webkit-font-smoothing: subpixel-antialiased;
}

footer a,
.type-label-lg {
    @include space-grotesk(12, 8); // Label Large
  font-weight: 300;
  display: inline-block;
    -webkit-font-smoothing: subpixel-antialiased;
}

.type-label-md {
    @include space-grotesk(10, 8); // Label Medium
    font-weight: 500;
    -webkit-font-smoothing: subpixel-antialiased;
}

.type-label-sm {
    @include space-grotesk(8, 4); // Label Small
    font-weight: 500;
    -webkit-font-smoothing: subpixel-antialiased;
}

p,
.type-body {
    @include lexend-deca(10, 16); // Body Medium
  font-weight: 300;
    -webkit-font-smoothing: subpixel-antialiased;

    @include r.lg {
        @include lexend-deca(12, 16);
    }
}
