@import "../../colors";

.blog-hero {
  width: 100%;
  padding-top: 80px;
}

.blog-hero-container {
  top: 80px;
  color: rgb(50, 60, 90);
  width: 100%;
}

.small-sinthu {
  width: 150px;
  height: 150px;
  background-image: url("../../assets/sinthu_outline.webp");
  background-size: 150% 100%;
  background-position: bottom center;
  background-repeat: no-repeat;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  background-position-x: -40px;
  margin: 0px auto 25px;
  border-radius: 25px;
  background-color: $base-color;
}

.newsletter-form {
  border: 1px solid $dark-border-color;
  border-radius: 4px;
}