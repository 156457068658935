@import "../colors";

.hint {
  height: 100%;
}

.hint-dark {
  color: white;
  background-color: rgba(255, 0, 255, 0.07);
  // background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(255, 0, 255, 0.07));
}

.hint-light {
  color: $dark-font-color;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(255, 0, 255, 0.05));
}