@import url('https://fonts.googleapis.com/css2?family=Sansita+Swashed:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@500;600');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@200;500;700&family=Playfair+Display&display=swap');
@import '../colors';

.my-card {
  color: $font-main-color !important;
  transition: all 1s ease-in;
}

.my-card-faded-in {
  transform: scale(1.0);
  background-color: transparent;
  color: white;
}


.sinthu-container {
  .profile-bg {
    background-image: radial-gradient($base-color 0.35px, rgba(255, 255, 255, 0.0) 0.35px);
    background-size: 7px 7px;
    min-width: 300px;
  }

}


.profile-bg {
  overflow-y: hidden;
  background-image: radial-gradient(rgba(255, 0, 89, 0.33) 0.35px, rgba(255, 255, 255, 0.0) 0.35px);
  background-size: 7px 7px;
}

@media(-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
  .polka-bg {
    background-image: radial-gradient(rgba(215, 215, 215, 1) 0.5px, rgba(0, 0, 0, 0) 0.5px);
    background-size: 5px 5px;
  }
  .polka-bg-red {
    background-image: radial-gradient(rgba(11, 7, 14, 0.1) 1px, rgba(0, 0, 0, 0.0) 1px);
    background-size: 5px 5px;
  }
  .sinthu-container {
    .profile-bg {
      background-image: radial-gradient($base-color 0.5px, rgba(255, 255, 255, 0.0) 0.5px);
      background-size: 10px 10px;
    }
  }
}

.pb {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
}


.sinthu {
  background-image: url("../assets/sinthu.png");
}

.janu {
  background-image: url("../assets/janujan.jpg");
}

.theresia {
  background-image: url("../assets/theresia.jpg");
}

.sanja {
  background-image: url("../assets/sanja.jpg");
}

.bg-red-accent {
  background: linear-gradient(to top, $base-color 0%, rgba(255, 255, 255, 0) 100%);
  width: 300px;
  margin: 0 auto;
}

.main-heading {
  font-size: 2.5rem; // 4.25
  line-height: normal; // 5.65
  transition: all 1s ease;
  transition-delay: 1s;
  flex-grow: 1;
  font-weight: 200 !important;
  backdrop-filter: blur(32px);
  -webkit-backdrop-filter: blur(32px);
  padding: 30px;
  background: linear-gradient(to top, rgba(255, 255, 255, 0.0), rgba(0, 0, 0, 0.7));
}

.sub-heading {
  font-size: 1.45rem;
  line-height: 1.45rem;
  margin-top: 15px;
  font-weight: 300 !important;
  display: block;

  svg {
    transform: scale(0.75);
  }
}

@media (max-width: 576px) {
  .main-heading {
    font-size: 2.15rem;
    line-height: 2.15rem;
    text-align: left;
  }
  .sub-heading {
    font-size: 1.35rem;
    line-height: 1.35rem;
    font-weight: 500;
  }
}

@media (max-width: 397px) {
  .sub-heading {
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: normal;
  }

}

@media (min-width: 577px) and (max-width: 768px) {
  .main-heading {
    font-size: 3rem;
    line-height: 3rem;
    border: none !important;
    text-align: center;
  }

  .sub-heading {
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: normal;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .main-heading {
    font-size: 3.5rem;
    line-height: 3.5rem;
    border: none !important;
    text-align: center;
  }

  .sub-heading {
    font-size: 1.75rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 992px) {
  .main-heading, .button-container {
    text-align: left;
    min-width: 600px;
  }

}
