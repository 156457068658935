@import "src/colors_red_theme.module";

$blue: $blue-accent;
$dark-bg: $dark-background-color;

.blue {
  color: $blue-accent !important;
}

.green {
  color: $green-accent !important;
}