@import "../colors";

.coverImage {
  max-width: 100%;
}

body {
}

#navigation {
  background-color: black;
}

.blog-preview:hover {
  cursor: pointer;
}

.card {
  font-family: "Lexend Deca", serif !important;
  font-weight: 300;
}

.card-img-top {
  -webkit-box-shadow: 3px 4px 12px 0px rgba(0, 0, 0, 0.17);
  box-shadow: 3px 4px 12px 0px rgba(0, 0, 0, 0.17);
  border-radius: 4px;
}

.blog-metrics .mdi {
  margin-right: 5px;
  font-size: 1rem !important;
}

.blog-title {
  color: $base-color;
}

.blog-metrics {
  font-size: 0.95rem;
  line-height: 30px;
  opacity: 0.7;
}

.blog-date {
  color: $dark-font-color !important;
}

.truncated-preview {
  text-align: justify;
}

.like-counter {
  border: 1px solid $dark-font-color;
  border-radius: 8px;
  padding: 10px;
  font-size: 1.2rem;
  min-width: 70px;
}

.heart {
  text-align: center;
  font-size: 1.7rem;
  color: $base-color !important;
}

.likes {
  text-align: center;
}

.like-counter:hover {
  cursor: pointer;
}

.bg-dark-background-color {
  background-color: $dark-bg;
}