
*,
*::before,
*::after {
	box-sizing: border-box;
}

html,
body,
ol,
ul {
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
}

ol,
ul {
	list-style: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

blockquote,
q {
	quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
	content: none;
}

a {
	color: inherit;
	text-decoration: none;
}

img {
	display: block;
}

input,
button {
	color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dd {
	margin: 0;
	font-weight: normal;
}

iframe {
	border: none;
}