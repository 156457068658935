@import "../colors";

#basic-navbar-nav {
  color: white;
}

.synexion-light, .synexion-dark {
  transition: fill .4s ease !important;
}

.nav-synexion-light, .nav-synexion-dark {
  transition: color .4s ease !important;
}

.synexion-light, .nav-synexion-light {
  fill: url(#header-shape-gradient);
  color: $dark-font-color !important;
}

.synexion-dark, .nav-synexion-dark {
  fill: white;
  color: white !important;
}

#header-shape-gradient {
  --color-stop: rgba(108, 64, 201, 1);
  --color-stop2: rgba(86, 58, 217, 1);
  --color-stop3: rgba(30, 110, 232, 1);
  --color-stop4: rgba(48, 131, 224, 1);
}

#navigation {
  background-color: rgba(1, 3, 5, 0.11);
  z-index: 1000;
  transition: background-color 0.3s ease;
}

.navbar-toggler {
  color: rgba(255, 255, 255, .55);
  border-color: rgba(255, 255, 255, .1);
}


@keyframes spin {
  0% {
    opacity: 0.0
  }
  50% {
    opacity: 1
  }
  100% {
    opacity: 0.0
  }
}

.navbar-nav > li {
  margin-right: 30px;
}

.navbar-nav .filled {
  border-radius: 8px;
  border: 1px solid $base-color;
  background-color: transparent;
}

.blur {
  backdrop-filter: blur(32px);
  -webkit-backdrop-filter: blur(32px);
}