@import "../colors";

.contact {
  #position: fixed;
  #bottom: 0;
  #right: 0;

  label {
    font-family: "Lexend Deca", serif;
    font-weight: 300;
  }

  input, select, textarea {
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.33);
    border-radius: 6px;
    margin-top: 8px;
    color: white;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(255, 0, 255, 0.04));
  }
}

.form-control-light {
  background-image: none !important;
  background-color: rgba(110, 110, 110, 0.08) !important;
  font-weight: 300;
  color: $dark-font-color !important;
  border-color: rgba(110, 110, 110, 0.18) !important;
}

.form-control-dark {
  background-image: none !important;
  background-color: rgba(110, 110, 110, 0.12) !important;
  font-weight: 300;
  color: white !important;
  border-color: rgba(110, 110, 110, 0.22) !important;
}

textarea[name=message] {
  resize: none;
}
