@import '../colors';

.socials-container {
  font-size: 1.8rem;
  text-align: center;

  .mdi {
    color: $base-color;
    margin: 0 10px;
    transition: transform 1s linear;
  }

  .mdi:hover {
    color: $base-color;
    transform: scale(1.1);
  }
}

.footer-icon {
  font-size: 1.6rem;
}