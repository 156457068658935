@mixin sm {
    @media (min-width: 640px) {
        @content;
    }
}

@mixin md {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin lg {
    @media (min-width: 1024px) {
        @content;
    }
}

@mixin xl {
    @media (min-width: 1280px) {
        @content;
    }
}

@mixin xxl {
    @media (min-width: 1600px) {
        @content;
    }
}

@mixin lt-sm {
    @media (max-width: 639.8px) {
        @content;
    }
}

@mixin lt-md {
    @media (max-width: 767.8px) {
        @content;
    }
}

@mixin lt-lg {
    @media (max-width: 1023.8px) {
        @content;
    }
}

@mixin lt-xl {
    @media (max-width: 1279.8px) {
        @content;
    }
}

@mixin lt-xxl {
    @media (max-width: 1599.8px) {
        @content;
    }
}

.r\:sm {
    @include lt-sm {
        display: none !important;
    }
}

.r\:md {
    @include lt-md {
        display: none !important;
    }
}

.r\:lg {
    @include lt-lg {
        display: none !important;
    }
}
.r\:xl {
    @include lt-xl {
        display: none !important;
    }
}
.r\:xxl {
    @include lt-xxl {
        display: none !important;
    }
}

.r\:lt-sm {
    @include sm {
        display: none !important;
    }
}

.r\:lt-md {
    @include md {
        display: none !important;
    }
}

.r\:lt-lg {
    @include lg {
        display: none !important;
    }
}
.r\:lt-xl {
    @include xl {
        display: none !important;
    }
}
.r\:lt-xxl {
    @include xxl {
        display: none !important;
    }
}
