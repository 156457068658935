@import "../colors";
.bg-highlight {
  background-color: $base-color;
}
.bg-green {
  background-color: $green-accent;
}
.bg-blue {
  background-color: $blue-accent;
}
.progress {
  height: 4px;
}

.skill-container {
  margin-top: 4rem;

  h5 {
    font-size: 1.3rem;
    line-height: 1.3rem;
    padding-bottom: 1.3rem;
  }
}