@import "../colors";

#consulting {
  background-image: linear-gradient(to bottom, #010305FF, #0b070e);
  backdrop-filter: blur(20px);

  h1, h2, h3 {
    color: white !important;
  }
}

.consulting-heading {

  h3 {
    font-weight: 300;
  }

  .consulting-icon {
    width: 90px !important;
    background-color: rgba(255, 255, 255, 0.03);
    position: relative;
    overflow: hidden;
    border-radius: 100%;
    height: 90px !important;
  }

  .consulting-icon div {
    animation: float 4.5s ease-in-out infinite;
    transform-origin: 50% 50%;
  }

  .consulting-icon::after {
    content: '';
    position: absolute;
    top: 0;
    left: -150%;
    width: 200%;
    height: 100%;
    background: linear-gradient(120deg, rgba(255, 255, 255, 0) 30%, rgba(86, 58, 217, 0.14) 50%, rgba(255, 255, 255, 0.0) 70%);
    animation: shimmer 4.8s infinite;
  }

  @keyframes shimmer {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes float {
    0%, 100% {
      transform: translateY(-3px) rotate(-2deg);
    }
    50% {
      transform: translateY(3px) rotate(2deg);
    }
  }

  .particle {
    position: absolute;
    bottom: 0;
    width: 4px;
    height: 4px;
    background-color: rgba(255, 255, 255, 0.4);
    animation: rise 3s ease-in-out forwards;
  }

  @keyframes rise {
    0% {
      transform: translateY(0) scale(1);
      opacity: 1;
    }
    100% {
      transform: translateY(-100px) scale(0);
      opacity: 0;
    }
  }

  .consulting-icon div {
    font-size: 3.25rem;
    line-height: 3.25rem;
    width: 100%;
    justify-content: center;
    top: 20px;
    position: relative;
    z-index: 1000;
  }
}

.gradient-text > span::before {
  background: linear-gradient(to right, $base-color 0%, rgba(86, 58, 217, 1) 44%, rgba(30, 110, 232, 1) 85%, rgba(48, 131, 224, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.gradient-text > div::before {
  background: linear-gradient(to right, $base-color 0%, rgba(86, 58, 217, 1) 44%, rgba(30, 110, 232, 1) 85%, rgba(48, 131, 224, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tag {
  padding: 3px 6px;
  border-radius: 6px;
  color: $base-color;
  font-size: 0.8rem;
  margin-right: 6px;
  margin-top: 6px;
  display: inline-block;
}

.tag.highlight {
  background-color: transparent;
  border: 1px solid $base-color;
  color: white !important;

  &.filled {
    background-color: $base-color;
  }
}

.tag.blue {
  background-color: transparent;
  border: 1px solid $blue;
  color: white !important;

  &.filled {
    background-color: $blue;
  }
}

.tag.green {
  background-color: transparent;
  border: 1px solid $green-accent;
  color: white !important;

  &.filled {
    background-color: $green-accent;
  }
}

@keyframes rotating {
  0% {
    -ms-transform: rotate(-7deg);
    -moz-transform: rotate(-7deg);
    -webkit-transform: rotate(-7deg);
    -o-transform: rotate(-7deg);
    transform: rotate(-7deg);
  }
  25% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -ms-transform: rotate(7deg);
    -moz-transform: rotate(7deg);
    -webkit-transform: rotate(7deg);
    -o-transform: rotate(7deg);
    transform: rotate(7deg);
  }
  75% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(-7deg);
    -moz-transform: rotate(-7deg);
    -webkit-transform: rotate(-7deg);
    -o-transform: rotate(-7deg);
    transform: rotate(-7deg);
  }
}

.rotating {
  -webkit-animation: rotating 2.5s linear infinite;
  -moz-animation: rotating 2.5s linear infinite;
  -ms-animation: rotating 2.5s linear infinite;
  -o-animation: rotating 2.5s linear infinite;
  animation: rotating 2.5s linear infinite;
}

.swiper-pagination-bullet {
  background-color: white;
}

.value-holder {
  opacity: 0;
  transition: opacity 0.33s;
}

.show {
  opacity: 1 !important;
}

.aspect {
  background-color: rgba(193, 124, 255, 0.1);
  backdrop-filter: blur(20px);
  border-radius: 12px;
  transition: transform 0.3s ease-in-out;
  transform: scale(1.0);
  z-index: 100000;
}


@media (hover: hover) and (pointer: fine) {
  .aspect:hover {
    transform: scale(1.05);
    cursor: pointer;

  }
}

.rocket {
  animation: shake 0.2s ease-in-out 8;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.bg-near-black {
  background-color: #0b070e;
}

.bg-effect {
  //background-color: rgba(255,255,255,0.06);
  background: linear-gradient(90deg, rgba(108, 64, 201, 1) 0%, rgba(86, 58, 217, 1) 44%, rgba(30, 110, 232, 1) 85%, rgba(48, 131, 224, 1) 100%);
}