@import '../colors';

.polka-bg {
  background-image: radial-gradient(rgba(215, 215, 215, 0.3) 0.5px, rgba(0, 0, 0, 0) 0.5px);
  background-size: 7px 7px;
}

.polka-bg-red {
  background-image: radial-gradient(#0b070e 0.5px, rgba(0, 0, 0, 0) 0.5px);
  background-size: 7px 7px;
}

.polka-visible {
  -webkit-transition: 1.1s ease-out;
  -moz-transition: 1.1s ease-out;
  -o-transition: 1.1s ease-out;
  transition: 1.1s ease-out;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -o-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  transform-origin: right;
  transition-delay: 0.3s;
}

.polka-hidden {
  transform-origin: right;
}